<template>
  <div id="addModify">
    <div class="headerDetail">
      <ds-header :title="headerTitle"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" labelAlign="right" :labelCol="{ style: 'width: 200px' }" :rules="rules">
      <div style="margin-top: 20px">
        <div class="content">
<!--          <a-form-model-item label="透出端口：" prop="penetrate" :span="24">-->
<!--            <a-radio-group-->
<!--              name="radioGroup"-->
<!--              v-model="form.penetrate"-->
<!--              :disabled="disabled"-->
<!--            >-->
<!--              <a-radio :value="'1'"> APP </a-radio>-->
<!--              <a-radio :value="'2'"> 微信小程序 </a-radio>-->
<!--            </a-radio-group>-->
<!--          </a-form-model-item>-->

          <a-form-model-item label="服务名称：" prop="serviceName" :span="24">
            <a-input
              :maxLength="8"
              :disabled="disabled"
              class="indexClass"
              placeholder="最少2个字数，最多8个字数"
              v-model.trim="form.serviceName"
            />
          </a-form-model-item>

          <a-form-model-item label="服务图标：" v-if="isShowServiceImg" prop="serviceIconUrl">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="IMG_API + '/oss/files'"
              :before-upload="beforeUpload"
              @change="handleChange"
              :disabled="disabled"
            >
              <img class="upImg" v-if="this.form.serviceIconUrl" :src="this.form.serviceIconUrl" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
            <span>图片类型支持jpg/png类型，最大不能超过2M,图片比例1:1</span>
          </a-form-model-item>

          <a-form-model-item label="常用图标：" v-if="isShowOftenIcon"  prop="oftenIcon">
            <a-upload
              name="file"
              list-type="picture-card"
              :action="IMG_API + '/oss/files'"
              class="avatar-uploader"
              :file-list="form.oftenIcon"
              @change="handleChangeIcon"
              @preview="handlePreview"
              :before-upload="beforeUploadIcon"
              :disabled="disabled"
            >
              <div v-if="this.form.oftenIcon.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <span>图片类型支持jpg/png类型，最大不能超过2M,图片比例1:1</span>
          </a-form-model-item>

          <a-form-model-item label="服务提供方：" prop="serviceProvideType" :span="24">
            <a-radio-group
              name="radioGroup"
              @change="serviceProvideTypeChange"
              v-model="form.serviceProvideType"
              :disabled="disabled"
            >
              <a-radio :value="1"> 第三方提供 </a-radio>
              <a-radio :value="2"> 自营服务 </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="页面形成：" prop="pageType" :span="24">
            <a-radio-group name="radioGroup" @change="radioChange" v-model="form.pageType" :disabled="disabled">
              <a-radio :value="1"> h5 </a-radio>
              <a-radio :value="2"> 原生页面 </a-radio>
              <a-radio :value="3"> 小程序页面 </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="页面入口：" prop="pageUrl" :span="24">
            <a-input
              class="indexClass"
              v-if="form.pageType !== 2"
              placeholder="请输入页面入口"
              v-model.trim="form.pageUrl"
              :disabled="disabled"
            />
            <a-select
              v-model="form.pageUrl"
              placeholder="请选择"
              show-search
              v-if="form.pageType === 2"
              class="indexClass"
              option-filter-prop="children"
              :filter-option="filterOption"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option v-for="option in pageEntryList" :key="option.serviceNumber" :value="option.serviceNumber">
                {{ option.serviceName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item v-show="serviceProvideTypeStatus" label="APP ID：" prop="otherAppletAppId" :span="24">
            <a-input
              :maxLength="50"
              class="indexClass"
              placeholder="请输入APP ID"
              v-model.trim="form.otherAppletAppId"
              :disabled="disabled"
            />
          </a-form-model-item>

          <a-form-model-item label="服务类型：" v-if="isShowServiceType" prop="serviceType" :span="24">
            <a-select
              style="width: 200px"
              placeholder="请选择"
              v-model="form.serviceType"
              :disabled="disabled"
            >
              <a-select-option v-for="item in this.serviceType" :value="item.id" :key="item.id">
                {{ item.serviceTypeName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item  label="用户身份限制：" required :span="24">
            <a-radio-group   v-model="form.identityAstrict" :disabled="disabled">
              <a-radio :value="1"> 无限制 </a-radio>
              <a-radio :value="2"> 企业认证 </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="是否实名认证：" v-if="isShowRealName" prop="realNameAuth" :span="24">
            <a-radio-group
              @change="
                () => {
                  form.enterpriseAuth = 0;
                }
              "
              name="radioGroup"
              v-model="form.realNameAuth"
              :disabled="disabled"
            >
              <a-radio :value="0"> 否 </a-radio>
              <a-radio :value="1"> 是 </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="是否企业认证：" v-if="isShowEnterpriseAuth" prop="enterpriseAuth" :span="24">
            <a-radio-group
              name="radioGroup"
              v-model="form.enterpriseAuth"
              :disabled="disabled || form.realNameAuth === 0"
            >
              <a-radio :value="0"> 否 </a-radio>
              <a-radio :value="1"> 是 </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item label="企业认证人群限制：" v-if="isShowEnterpriseCrowd">
            <a-checkbox-group :disabled="disabledIdentityAstrict || disabled" v-model="form.enterpriseCrowd">
              <a-checkbox v-for="(item, index) in enterpriseIdentityLimitData" :value="item.value" :key="index">
                {{ item.label }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>

          <a-form-model-item
            v-show="serviceProvideTypeStatus"
            label="同步登陆信息："
            prop="synchronizationsInformation"
            :span="24"
          >
            <a-checkbox-group @change="onChange" v-model="form.synchronizationsInformation" :disabled="disabled">
              <a-checkbox v-for="item in this.loginInformation" :value="item.id" :key="item.id">
                {{ item.name }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>

          <a-form-model-item label="备注：" prop="remarks" :span="24">
            <a-input
              :maxLength="20"
              class="indexClass"
              placeholder="最多可输入20个字"
              v-model.trim="form.remarks"
              :disabled="disabled"
            />
          </a-form-model-item>
          <a-form-model-item label="返回按钮" :span="24">
            <a-radio-group
              name="radioGroup"
              v-model="form.showBackBtn"
              :disabled="disabled"
            >
              <a-radio :value="0"> 不显示 </a-radio>
              <a-radio :value="1"> 显示 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="页面标题：" :span="24">
            <a-input
              :maxLength="20"
              class="indexClass"
              placeholder="请输入标题"
              v-model.trim="form.pageTitle"
              :disabled="disabled"
            />
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
    <div class="footer">
      <a-button key="submit" style="margin-right: 30px" type="primary" @click="onSubmit" v-if="this.type !== 'view'"
      >保存
      </a-button>
    </div>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import {
  newAddService,
  getTypeList,
  newEditServiceInfo,
  newDetailServiceInfo,
  serviceNumberList,
} from "@/api/serviceTypeList";

export default {
  data() {
    let checkPageUrl = (rule, value, callback) => {
      if (value) {
        return callback();
      }
      if (this.form.pageType === 2) {
        // 不合法
        callback(new Error("请选择页面入口"));
      } else {
        // 不合法
        callback(new Error("请输入页面入口"));
      }
    };
    return {
      // 企业认证人群限制
      enterpriseIdentityLimitData: [
        {
          label: '科创人才',
          value: '1'
        },
        {
          label: '服务团队',
          value: '2'
        },
        {
          label: '重点客户',
          value: '3'
        },
        {
          label: '普通用户',
          value: '4'
        }
      ],
      id: "",
      type: "",
      disabledIdentityAstrict: false,
      // 是否展示服务图标
      isShowServiceImg: false,
      // 是否展示常用服务
      isShowOftenIcon: false,
      // 是否展示服务类型
      isShowServiceType: false,
      // 是否展示实名认证
      isShowRealName: false,
      // 是否展示企业认证
      isShowEnterpriseAuth: false,
      // 是否展示企业认证人群限制
      isShowEnterpriseCrowd: false,
      headerTitle: "新建服务",
      IMG_API: IMG_API,
      disabled: false,
      // 上传图片loading
      loading: false,
      // 服务提供方状态
      serviceProvideTypeStatus: true,
      // 服务类型
      serviceType: [],
      form: {
        // 服务名称
        serviceName: "",
        // 服务提供方
        serviceProvideType: 1,
        // 服务图标
        serviceIconUrl: "",
        //常用图标
        oftenIcon: [],
        // 页面形成
        pageType: 1,
        // 页面入口
        pageUrl: "",
        // APP ID
        otherAppletAppId: "",
        // 用户限制
        identityAstrict: 1,
        // 企业认证人群限制
        enterpriseCrowd: [],
        // 是否实名认证
        realNameAuth: 0,
        // 是否企业认证
        enterpriseAuth: 0,
        // 服务类型
        serviceType	: '',
        // 透出端口
        penetrate: '1',
        // 同步登陆信息
        synchronizationsInformation: [],
        // 备注
        remarks: "",
        // 返回标题
        showBackBtn: 0,
        // 页面标题
        pageTitle: "",
      },
      rules: {
        // 服务名称
        serviceName: [
          { required: true, message: "请填写服务名称", trigger: "blur" },
          { pattern: "^[\u4e00-\u9fa5_a-zA-Z0-9]+$", message: "不能输入特殊字符以及空格", trigger: "blur" },
          { min: 2, max: 8, message: "长度在 2 到 8 个字符", trigger: "blur" },
        ],
        // 服务图标
        serviceIconUrl: [{ required: true, message: "请上传服务图标", trigger: "change" }],
        // 服务提供方
        serviceProvideType: [{ required: true, message: "请选择服务提供方", trigger: "change" }],
        // 页面形成
        pageType: [{ required: true, message: "请选择页面形成", trigger: "change" }],
        // 实名认证
        realNameAuth: [{ required: true, message: "请选择", trigger: "change" }],
        // 企业认证
        enterpriseAuth: [{ required: true, message: "请选择", trigger: "change" }],
        // 页面入口
        pageUrl: [
          { validator: checkPageUrl, trigger: "blur" },
          { required: true, pattern: new RegExp(/^[^\s]*$/, "g"), message: "禁止输入空格" },
        ],
        // 透出端口
        penetrate: [{ required: true, message: "请选择透出端口", trigger: "change" }],
        // 服务类型
        serviceType	: [{ required: true, message: "请选择服务类型", trigger: "change" }],
      },
      // 同步登录信息
      loginInformation: [
        {
          id: "1",
          name: "账号名称",
        },
        {
          id: "2",
          name: "手机号",
        },
        {
          id: "3",
          name: "性别",
        },
        {
          id: "4",
          name: "姓名",
        },
        {
          id: "5",
          name: "身份证号码",
        },
      ],
      // 页面入口下拉列表
      pageEntryList: [],
    };
  },
  watch: {
    "form.penetrate": {
      handler(val) {
        if (val === '2') {
          // 是否展示服务类型
          this.isShowServiceType = true
          this.isShowServiceImg = true
          this.isShowOftenIcon = true
          this.isShowRealName = true
          this.isShowEnterpriseAuth = true
          this.isShowEnterpriseCrowd = false
        } else {
          this.isShowServiceType = false
          this.isShowServiceImg = false
          this.isShowOftenIcon = false
          this.isShowRealName = false
          this.isShowEnterpriseAuth = false
          this.form.oftenIcon = ''
          this.form.serviceIconUrl = ''
          this.form.realNameAuth = 0
          this.form.enterpriseAuth = 0
          this.form.serviceType = ''
          this.isShowEnterpriseCrowd = true
        }
      },
      deep: true,
      immediate: true,
    },
      "form.identityAstrict": {
        handler(val) {
          if (val == 1) {
            this.disabledIdentityAstrict = true
            this.form.enterpriseCrowd = []
          } else {
            this.disabledIdentityAstrict = false
          }
        },
        deep: true,
        immediate: true
      }
  },
  created() {
    this.id = this.$route.query.id ? this.$route.query.id : null;
    this.type = this.$route.query.type;
    this.getTypeList();
    this.getPageEntry();
    this.type = this.$route.query.type;
    if (this.type == "view") {
      this.disabled = true;
    }
    if (this.type !== "add") {
      this.headerTitle = "编辑服务";
      this.detailServiceInfo();
    }
  },
  methods: {
    //获取原声页面列表下拉值
    async getPageEntry() {
      const res = await serviceNumberList();
      res.data.forEach((item) => {
        item.serviceName = item.serviceNumber + "(" + item.serviceName + ")";
      });
      this.pageEntryList = res.data;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 服务提供方change事件
    serviceProvideTypeChange() {
      // 判断服务方是否是自营服务，自营服务隐藏部分输入框
      if (this.form.serviceProvideType === 2) {
        this.serviceProvideTypeStatus = false;
        this.form.otherAppletAppId = "";
        this.form.synchronizationsInformation = "";
      } else {
        this.serviceProvideTypeStatus = true;
      }
    },
    // 查询详情
    async detailServiceInfo() {
      const res = await newDetailServiceInfo(this.id);
      this.form = {
        id: res.data.id,
        // 服务名称
        serviceName: res.data.serviceName,
        // 服务图标
        serviceIconUrl: res.data.serviceIconUrl,
        // 服务提供方
        serviceProvideType: res.data.serviceProvideType,
        // 用户身份限制
        identityAstrict: res.data.identityAstrict,
        // 页面形成
        pageType: res.data.pageType,
        // 服务类型
        serviceType: res.data.serviceType.toString(),
        // 页面入口
        pageUrl: res.data.pageUrl,
        // APP ID
        otherAppletAppId: res.data.otherAppletAppId,
        // 企业认证人群限制
        enterpriseCrowd: res.data.enterpriseCrowd,
        //实名认证
        realNameAuth: res.data.realNameAuth,
        //企业认证
        enterpriseAuth: res.data.enterpriseAuth,
        // 透出端口
        penetrate: res.data.penetrate.toString(),
        // 同步登陆信息
        synchronizationsInformation: res.data.synchronizationsInformation,
        // 备注
        remarks: res.data.remarks,
        // 返回标题
        showBackBtn: res.data.showBackBtn,
        // 页面标题
        pageTitle: res.data.pageTitle
      };
      if (res.data.oftenIcon) {
        let oftenIcon = {
          uid: "1",
          name: "image.png",
          status: "done",
          url: res.data.oftenIcon,
        };
        this.form.oftenIcon = [oftenIcon];
      } else {
        this.form.oftenIcon = [];
      }
      // 同步登陆信息传值处理
      this.form.synchronizationsInformation = JSON.parse(this.form.synchronizationsInformation).toString().split(",");
      this.form.enterpriseCrowd = JSON.parse(this.form.enterpriseCrowd).toString().split(",")
    },

    // 页面形成change
    radioChange() {
      this.form.pageUrl = "";
      this.$refs.ruleForm.clearValidate("pageUrl");
    },
    // 服务图片上传
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.form.serviceIconUrl = info.file.response.redirect_uri;
      }
    },
    // 获取服务类型
    async getTypeList() {
      const res = await getTypeList();
      if (res.code == 200) {
        this.serviceType = res.data;
      }
    },

    // 保存
    onSubmit() {
      let obj = {
        ...this.form,
      };
      if (obj.oftenIcon.length != 0) {
        obj.oftenIcon = obj.oftenIcon[0].response ? obj.oftenIcon[0].response.redirect_uri : obj.oftenIcon[0].url;
      } else {
        obj.oftenIcon = "";
      }
      if (obj.penetrate.length == 2) {
        obj.penetrate = 3;
      }
      if (obj.remarks == "") {
        obj.remarks = undefined;
      }
      obj.synchronizationsInformation = `[${obj.synchronizationsInformation}]`;
      obj.enterpriseCrowd = `[${obj.enterpriseCrowd}]`;
      obj.penetrate = obj.penetrate.toString();
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.type == "add") {
            const res = await newAddService(obj);
            try {
              if (res.code === '200') {
                this.$message.success("新增成功");
                this.$router.go(-1);
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) {}
          } else if (this.type == "edit") {
            obj.Id = this.id;
            const res = await newEditServiceInfo(obj);
            try {
              if (res.code === "200") {
                this.$message.success("编辑成功");
                this.$router.go(-1);
              } else {
                this.$message.error(res.msg);
                // this.detailServiceInfo();
              }
            } catch (error) {}
          }
        } else {
          return false;
        }
      });
    },

    // 透出端口复选框
    onChange(checkedValues) {
    },
    // 常用图标图片上传
    handleChangeIcon({ fileList }) {
      if (fileList.length != 0) {
        if (this.commonlyUsedType) {
          this.form.oftenIcon = fileList;
          this.$forceUpdate();
        }
      } else {
        this.form.oftenIcon = [];
        this.$forceUpdate();
      }
    },
    // 图片限制
    beforeUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 2;
      if (!isLt2M) {
        this.$message.error("图片最大2M");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },
    beforeUploadIcon(file) {
      // 校验格式
      this.commonlyUsedType = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJpgOrPng = type === "png" || type === "jpg";
      this.isJpgOrPng = isJpgOrPng;
      if (!isJpgOrPng) {
        this.$message.error("图片不规范，请按要求上传");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 2;
      this.isLt2M = isLt2M;
      if (!isLt2M) {
        this.$message.error("图片不规范，请按要求上传");
        return Promise.reject();
      }
      // 校验比例
      this.commonlyUsedType = isJpgOrPng && isLt2M;
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          this.commonlyUsedType = false;
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },
  },
};
</script>

<style scoped lang="scss">
.indexClass {
  width: 300px;
}

.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

::v-deep .ant-form-item {
  display: flex;
  margin-bottom: 0;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.content {
  /* margin-left:24px; */
  margin-top: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}

::v-deep .ant-checkbox-group {
  width: 100%;
  .ant-checkbox-group-item {
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
::v-deep #editer {
  tr {
    height: 28.5px;
  }
}
::v-deep .ant-table-thead {
  th {
    &:before {
      content: "*";
      color: red;
    }
  }

  th:nth-child(4) {
    &:before {
      content: "";
    }
  }
}
::v-deep .ant-select-dropdown {
  z-index: 99999;
}
::v-deep .ant-modal-close-x {
  margin: -13px -13px 0 0;
}
.upImg {
  width: 109px;
  height: 109px;
}
</style>
